import React from "react";
import Layout from "../components/layout";
import "./sub.scss";
import { Link } from "gatsby";

const SubPage = () => {
  return (
    <Layout>
      <section className="subpage">
        {/* <div className="fv">
          <div className="container">
            <div className="fv-txt" data-slide="1">
              <h1 className="fv-txt-heading">
                サンプルテキストサンプルテキストサンプルテキストサンプルテキスト①
              </h1>
              <p className="fv-txt-lead">
                サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              </p>
            </div>
            <div className="fv-txt" data-slide="2">
              <h1 className="fv-txt-heading">
                サンプルテキストサンプルテキストサンプルテキストサンプルテキスト②
              </h1>
              <p className="fv-txt-lead">
                サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              </p>
            </div>
            <div className="fv-txt" data-slide="3">
              <h1 className="fv-txt-heading">
                サンプルテキストサンプルテキストサンプルテキストサンプルテキスト③
              </h1>
              <p className="fv-txt-lead">
                サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              </p>
            </div>
            <div className="fv-anime">
              <div className="fv-anime-container">
                <div className="bg"></div>
                <div className="right">
                  <div className="check"></div>
                </div>
                <div className="left">
                  <div className="check"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <p>サブページ</p>
          <br />
          <br />
          <Link to="/">TOPページにもどる</Link>
        </div>
      </section>
    </Layout>
  );
};

export default SubPage;
